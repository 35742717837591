import React from "react";
import { rgba } from "polished";
import { spacing } from "@mui/system";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import styled, { css } from "styled-components/macro";
import {
  Card as MuiCard,
  Chip as MuiChip,
  Typography as MuiTypography,
} from "@mui/material";

import { customBlue as blue } from "../../config/constants";

const Typography = styled(MuiTypography)(spacing);
const Card = styled(MuiCard)`
  position: relative;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  margin-bottom: 10px;
  padding-left: 5px;
  padding-top: 5px;
  padding-right: 5px;
  ${(props) => !!props.$rowview ? `min-width: 15vw;` : `max-width: 340px;min-width: 340px;`}
  ${(props) =>
    !!props.$illustration &&
    props.theme.palette.mode !== "dark" &&
    css`
      background: ${(props) => rgba(props.theme.palette.primary.main, 0.125)};
      color: ${(props) => props.theme.palette.primary.main};
    `
  }
`;

const CardContent = styled.div`
  h6 {
    font-size: 0.9rem;
  }
  padding: 0px;
  position: relative;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: stretch;
  text-decoration: none;
`;

const Chip =
  styled(MuiChip)`
  cursor: pointer;
  text-decoration: none;
  top: 16px;
  right: 16px;
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  border-radius: 4px;
  background-color: ${(props) => props.theme.palette.secondary.main};
  color: ${(props) => props.theme.palette.common.white};
  margin-bottom: ${(props) => props.theme.spacing(4)};

  span {
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;

const InfoScetion = styled.div`
  margin-top: 2px;
  display: flex;
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex-direction: column;

  ${(props) => !!props.$rowview ? `
    align-content: center;
    align-items: flex-start;
    margin-left:15px;
    flex-wrap: nowrap;
    flex-direction: column;
  ` : `
    align-content: center;
    justify-content: center;
    align-items: center;
    overflow-x: scoll;
    flex-wrap: nowrap;
    flex-direction: column;
  `}

  span {
    color: ${(props) => props.percentageColor};
    font-weight: ${(props) => props.theme.typography.fontWeightBold};
    padding: 2px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 3px;
    ${(props) => !props.$rowview && `min-width: 115px;`}
    margin-right: ${(props) => props.theme.spacing(2)};
  }

  div {
    ${(props) => !props.$rowview && `
      max-width: 320px;
      min-width: 320px;
    `}
  }

  p {
    ${(props) => !props.$rowview && `max-width: 320px;`}
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  ${(props) =>
    !!props.$illustration &&
    props.theme.palette.mode !== "dark" &&
    css`
      color: ${(props) => rgba(props.theme.palette.primary.main, 0.85)};
    `
  }
`;

const InfoContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  min-height: 30px;
  max-height: 30px;
  margin-top: 5px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  p {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  span {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

const InnerInfoContainerRow = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1507px) {
    width: 100%;
  }
`;

export const getColor = (value) => {
  switch (value.toLocaleLowerCase()) {
    case "low":
      return "#66bb6a";
    case "medium":
      return "#afb42b";
    case "high":
      return "#d32f2f";
    case "critical":
      return "#d32f2f";
    default:
      return "#81d4fa";
  }
};

const MainCard = ({
  Name: name,
  illustration,
  rowview,
  exploreLink,
  ...rest
}) => {

  const user = {};
  return (
    <div
      id={`${name}`}
      style={{ scrollMarginTop: "60px", marginLeft: "5px", marginRight: "5px" }}>
      <Card $illustration={illustration} $rowview={rowview}>
        <CardContent>
          <TitleWrapper>
            <Typography variant="h6" mx={2} mr={5}>
              {name}
            </Typography>
            {!illustration && exploreLink && (
              <Link style={{ textDecoration: "none" }} to={exploreLink}>
                <Tooltip title={"Quick Access"}>
                  <Chip label={"EXPLORE"} />
                </Tooltip>
              </Link>
            )}
          </TitleWrapper>
          <InfoScetion
            percentageColor={blue[500]}
            $illustration={illustration}
            $rowview={rowview}>
            {rest["threatCount"] && (
              <InfoContainer>
                <InnerInfoContainerRow>
                  <span>Threat Count</span>
                  <p>{rest.groups.includes(user.group) ? rest["threatCount"] : "N/A"}</p>
                </InnerInfoContainerRow>
              </InfoContainer>
            )}
            {rest["controlCount"] && (
              <InfoContainer>
                <InnerInfoContainerRow>
                  <span>Control Count</span> 
                  <p>{rest.groups.includes(user.group) ? rest["controlCount"] : "N/A"}</p>
                </InnerInfoContainerRow>
              </InfoContainer>
            )}
            {rest["controlObjectives"] && (
              <InfoContainer style={{ marginBottom: "10px" }}>
                <InnerInfoContainerRow>
                  <span>Control Objectives</span>
                  <p>{rest.groups.includes(user.group) ? rest["controlObjectives"] : "N/A"}</p>
                </InnerInfoContainerRow>
              </InfoContainer>
            )}
          </InfoScetion>
        </CardContent>
      </Card>
    </div>
  );
};

export default MainCard;
