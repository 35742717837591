import { Navigate } from "react-router-dom";

import { Main } from "./pages/main";
import { MainLayout } from "./layouts/main";
import { SimpleLayout } from "./layouts/simple";
import { allowedUrls } from "./config/constants";
import { TermsOfService } from "./pages/misc/tos";
import { PrivacyPolicy } from "./pages/misc/privacy";

export const routes = [
  {
    path: "/",
    element: <Navigate to="/aws" />
  },
  {
    path: "/aws",
    element: <Navigate to={`/aws/${allowedUrls.mapping}`} />
  },
  {
    path: "/privacy",
    element: <SimpleLayout />,
    children: [
      {
        path: "",
        element: <PrivacyPolicy />,
      },
    ],
  },
  {
    path: "/site-terms",
    element: <SimpleLayout />,
    children: [
      {
        path: "",
        element: <TermsOfService />,
      },
    ],
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <Main />,
      },
      {
        path: ":provider/:appName",
        element: <Main />
      }
    ],
  }
];
