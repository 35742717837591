import { updateSections } from "../components/sidebar/sidebarMenu";

let servicesConfig = {};

const loadServicesConfig = async () => {
  const updatedSections = await updateSections();
  servicesConfig = getServicesConfig(updatedSections);
};

const getServicesConfig = (sections) => {
  const config = {};
  sections.forEach((section) => {
    const name = section.title;
    config[name] = {};
    config[name].title = section.title;
    section.children.forEach(({ title, url, groups }) => {
      config[name][title] = {};
      config[name][title].title = title;
      config[name][title].url = url;
      config[name][title].groups = groups;
    });
  });
  return config;
};

export const getServiceTitle = (href) => {
  let title = "";
  Object.values(servicesConfig).forEach((section) => {
    Object.values(section).forEach((service) => {
      if (service.url && service.url.endsWith(href))
        title = service.title;
    });
  });
  return title;
};

// Load services configuration
loadServicesConfig();

export { servicesConfig };
