import React from "react";
import { styled } from "@mui/styles";
import { useParams } from "react-router-dom";
import { withTheme } from "styled-components/macro";
import { Menu as MenuIcon } from "@mui/icons-material";
import {
  Grid,
  Hidden,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Typography
} from "@mui/material";

import { allowedApps } from "../../config/constants";

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  '&': {
    backgroundColor: theme.header.background,
    color: theme.header.color,
    borderBottom: "1px solid #DDD",
    boxShadow: "1px 1px 1px #DDD"
  }
}));

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Navbar = ({ onDrawerToggle }) => {
  const { appName } = useParams();

  return (
    <React.Fragment>
      <AppBar
        position="sticky"
        elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" sx={{ paddingRight: "1.5rem" }}>
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                  size="large">
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid
              item
              xs
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginLeft: "2vw",
              }}>
              <Typography variant="h3" gutterBottom>
                {allowedApps.filter(app => app.url === appName).pop().title}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment >
  );
};

export default withTheme(Navbar);
