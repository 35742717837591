import { styled } from "@mui/system";
import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  TextField,
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";


const StyledAccordion = styled(Accordion)(({ theme }) => ({
  "&": {
    boxShadow: "0px 1px 2px #AAA",
    padding: "0rem 0.8rem 0rem 0.8rem"
  },
  "& .MuiAccordionSummary-root .MuiTypography-root": {
    fontSize: "14px"
  },
  "& pre": {
    marginTop: 0,
    backgroundColor: "rgba(250, 250, 250)",
    padding: "1rem",
    borderRadius: "5px",
    border: "1px solid #DDD"
  }

}));

const preserveKeys = [
  "useridentity",
  "eventsource",
  "eventname",
  "awsregion",
  "sourceipaddress",
  "useragent",
  "SignatureVersion",
  "CipherSuite",
  "AuthenticationMethod",
  "eventtype",
];

export const EventsTable = ({ data }) => {
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const handleSearchChange = (text) => {
    setSearchText(text);
    const filteredResults = data.filter(item =>
      item.eventname.toLowerCase().includes(text.toLowerCase())
    );
    setFilteredData(filteredResults);
  };

  const getType = (value) => {
    if (typeof value === "string") {
      if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z/.test(value))
        return "<datetime>";
      return "<string>";
    } else if (typeof value === "number" && Number.isInteger(value)) {
      return "<integer>";
    } else if (typeof value === "number" && !Number.isInteger(value)) {
      return "<float>";
    } else if (typeof value === "boolean") {
      return "<boolean>";
    } else if (value === null) {
      return null;
    } else if (Array.isArray(value)) {
      const arrayType = value.length > 0 ? getType(value[0]) : "string";
      return "array<" + arrayType + ">";
    }

    return value;
  }

  const mapObject = (obj) => {
    const result = {};

    for (const key in obj) {
      if (preserveKeys.includes(key)) {
        result[key] = obj[key]; // Preserve value as is for specific keys
      } else if (typeof obj[key] === "object") {
        result[key] = mapObject(obj[key], preserveKeys);
      } else {
        result[key] = getType(obj[key]);
      }
    }

    return result;
  }

  const formatObject = (obj) => {
    const objString = JSON.stringify(mapObject(obj), null, 2);
    return objString
      .replace(/"</g, "<")
      .replace(/>"/g, ">")
      .replace(/"array/g, "array");
  }

  useEffect(() => setFilteredData(data), [data]);

  return (
    <>
      <TextField
        fullWidth
        label="Search Events"
        variant="outlined"
        value={searchText}
        onChange={(e) => handleSearchChange(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{marginBottom: "2rem"}}
      />
      <div>
        {filteredData.map((item, index) => (
          <StyledAccordion key={index} sx={{ boxShadow: "0px 1px 2px #AAA" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel-${index}-content`}
              id={`panel-${index}-header`}
            >
              <Typography>{item.eventname}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ maxWidth: "100%", overflowX: "auto" }}>
              <pre style={{ whiteSpace: "pre-wrap" }}>
                {formatObject(item)}
              </pre>
            </AccordionDetails>
          </StyledAccordion>
        ))}
      </div>
    </>
  );
};
