import styled from "styled-components/macro";
import {
  Grid,
  Hidden,
  List,
  ListItemText as MuiListItemText,
  ListItemButton as MuiListItemButton,
  Divider,
} from "@mui/material";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(0.25)};
    ${(props) => props.theme.spacing(4)};
  background: ${(props) => props.theme.footer.background};
  position: relative;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
`;

const ListItemButton = styled(MuiListItemButton)`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)};
  padding-right: ${(props) => props.theme.spacing(2)};
  &:hover {
    background: #e5f6fd;
    border-radius: 4px;
  }
`;

const ListItemText = styled(MuiListItemText)`
  span {
    color: ${(props) => props.theme.footer.color};
  }
`;

export const Footer = () => (
  <Wrapper>
    <Grid container spacing={0}>
      <Hidden mdDown>
        <Grid container item xs={12} md={6}>
          <List sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItmes: "center"
          }}>
            <ListItemButton component="a" href="/privacy">
              <ListItemText primary="Privacy" />
            </ListItemButton>
            <Divider orientation="vertical" sx={{
              borderRightWidth: 2,
              margin: "1px 1px"
            }}/>
            <ListItemButton component="a" href="/site-terms">
              <ListItemText primary="Terms of Service" />
            </ListItemButton>
          </List>
        </Grid>
      </Hidden>
      <Grid container item xs={12} md={6} justifyContent="flex-end">
        <List>
          <ListItemText sx={{ marginTop: "0.55rem", marginRight: "3rem" }}
            primary={`© ${new Date().getFullYear()} - Cloud Security Dataset`}
          />
        </List>
      </Grid>
    </Grid>
  </Wrapper>
);
