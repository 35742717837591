import React from "react";
import { Badge, Grid } from "@mui/material";
import styled from "styled-components/macro";

const Footer = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background
  } !important;
  padding: ${(props) => props.theme.spacing(2.75)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const FooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) =>
      props.theme.sidebar.footer.online.background
    };
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const SidebarFooter = ({ ...rest }) => (
  <Footer {...rest}>
    <Grid container spacing={2}>
      <Grid item>
        <FooterBadge
          overlap="circular"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          variant="dot">
        </FooterBadge>
      </Grid>
    </Grid>
  </Footer>
);

export default SidebarFooter;
