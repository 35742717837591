import React from "react";
import { useLocation } from "react-router-dom";

import SidebarNavListItem from "./sidebarNavListItem";

const SidebarNavList = (props) => {
  const { pages, depth } = props;

  const router = useLocation();
  const currentRoute = router.pathname;
  const user = {group: "guest"};

  const sidebarItems = ({ items, page, depth, currentRoute, user }) => {

    if (page.children) {
      const open = page.children.filter(
        (page) => currentRoute.endsWith(page.href)
      ).length ?? false;

      items.push(
        <SidebarNavListItem
          depth={depth}
          icon={page.icon}
          key={open ? `${page.title}_open` : page.title}
          badge={page.badge}
          // open={!!open}
          title={page.title}
          href={page.href}>
          <SidebarNavList depth={depth + 1} pages={page.children} />
        </SidebarNavListItem>
      );
    } else {
      items.push(
        <SidebarNavListItem
          depth={depth}
          href={page.href}
          icon={page.icon}
          key={page.title}
          badge={page.badge}
          title={page.title}
        />
      );
    }
    return items;
  };

  return <>
    {
      pages.reduce(
        (items, page) =>
          sidebarItems({ items, page, currentRoute, depth, user }),
        []
      )
    }
  </>
};

export default SidebarNavList;
