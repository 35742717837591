export const apiUrl = process.env.REACT_APP_API_ENDPOINT;

export const allowedUrls = {
  mapping: "mapping",
  events: "events"
};

export const allowedApps = [
  {
    title: "API & IAM Mapping",
    url: allowedUrls.mapping
  },
  {
    title: "Cloud Events",
    url: allowedUrls.events
  }
];

// Themes
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

export const customBlue = {
  50: "#d6f500",
  100: "#e6f9ff",
  200: "#d6f5ff",
  300: "#d6f5ff",
  400: "#d6f5ff",
  500: "#00aaff",
  600: "#00aaff",
  700: "#00aaff",
  800: "#00aaff",
  900: "#00aabf",
};

export const grey = {
  50: "#f8f9fa",
  100: "#f8f9fa",
  200: "#f8f9fa",
  300: "#f8f9fa",
  400: "#f8f9fa",
  500: "#292929",
  600: "#292929",
  700: "#292929",
  800: "#292929",
  900: "#6fff29",
};
