import React from "react";
import { Outlet } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import styled, { ThemeProvider } from "styled-components/macro";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";

import createTheme from "../theme";
import { THEMES } from "../config/constants";
import GlobalStyle from "../services/styles/global";

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const SimpleLayout = ({ children }) => (
  <MuiThemeProvider theme={createTheme(THEMES.DEFAULT)}>
    <ThemeProvider theme={createTheme(THEMES.DEFAULT)}>
      <Root>
        <CssBaseline />
        <GlobalStyle />
        <AppContent>
          {children}
          <Outlet />
        </AppContent>
      </Root>
    </ThemeProvider>
  </MuiThemeProvider>
);
