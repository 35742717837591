import { Tabs } from "@mui/material";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { Tab as MuiTab } from "@mui/material";
import { Chip as MuiChip } from "@mui/material";

import { customBlue } from "../../config/constants";
import { scrollToEl } from "../../services/scrolling";

const Tab = styled(MuiTab)`
  padding: 0px;
  width: 150px;
  display: flex;
  align-items: flex-end;
`;

const Chip = styled(MuiChip)`
  margin: 0px;
  padding: 0px;
  span {
    margin: 0px;
    text-align: end;
  }
  font-size: 1rem;
  border: none;
  cursor: pointer;
`;

export const ProviderTabs = ({ labels, contents, activeTab, vertical }) => {
  const [hash, setHash] = useState(decodeURI(activeTab));
  const [selected, setSelected] = useState(activeTab?.split("?")[0]);

  const handleChange = (event, value) => setSelected(value);

  useEffect(() => {
    scrollToEl(hash);
    window.addEventListener("hashchange", (event) => {
      setHash(decodeURI(window.location.hash));
    });
  }, []);

  useEffect(() => {
    const tab = hash.split("?")[0];
    if (tab !== selected)
      setSelected(tab);
    scrollToEl(hash);
  }, [hash]);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Tabs
        TabIndicatorProps={{ style: { background: customBlue[500] } }}
        value={
          selected && labels.includes(decodeURI(selected).replace("#", ""))
            ? decodeURI(selected) : `#${labels[0]}`
        }
        onChange={handleChange}
        orientation={vertical ? "vertical" : "horizontal"}
        variant="scrollable"
        indicatorColor="secondary"
        style={{ width: "200px", }}>
        {labels.map((label, index) => (
          <Tab
            key={index}
            label={<Chip label={label} variant="outlined" ></Chip>}
            value={`#${label}`}
            component={Link}
            to={
              window.location.pathname.split("/").slice(0, 4).join("/") +
              `#${label}`
            }
            LinkComponent={() => contents[index]}
          />
        ))}
      </Tabs>
      {contents.map((content, index) => (
        <div
          key={index}
          id={labels[index]}
          style={
            selected === `#${labels[index]}` ? {
              scrollMarginTop: "100px",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              flex: 1,
            }
            : { display: "none" }
          }>
          {content}
        </div>
      ))}
    </div>
  );
};
