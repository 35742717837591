import React from "react";
import { spacing } from "@mui/system";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";
import { Button as MuiButton, Typography } from "@mui/material";

import NavbarIcon from "../../components/navbar/navbarIcon";

const Button = styled(MuiButton)(spacing);
const Wrapper = styled.div`
  padding-top: ${(props) => props.theme.spacing(6)};
`;

const InnerWidthCont = styled.div`
  width: 90%;
  margin: 0 auto;
  text-align: justify;
`;

export const TermsOfService = () => {
  return (
    <>
      <NavbarIcon />
      <Wrapper>
        <Helmet title="Terms Of Service" />
        <Typography align="center">
          <Button
            component={Link}
            to="/"
            variant="contained"
            color="secondary"
            mt={2}>
            Return to website
          </Button>
        </Typography>
        <InnerWidthCont>
            <h1>
                Site Terms
            </h1>
            <p>
              <em>Last Updated: April 3, 2020</em>
            </p>

            <p>
              Welcome to the TrustOnCloud site (the “TrustOnCloud Site”).
              Emerging Technology Trusted Advisors Pte. Ltd. and/or its
              affiliates (“TrustOnCloud ”) provides the TrustOnCloud Site to you
              subject to the following terms of use (“Site Terms”). By visiting
              the TrustOnCloud Site, you accept the Site Terms. Please read them
              carefully. In addition, when you use any current or future
              TrustOnCloud services, content or other materials, you also will
              be subject to a TrustOnCloud Customer Agreement or other agreement
              governing your use of our services as may be applicable.
            </p>

            <h3>PRIVACY</h3>

            <p>
              Please review our&nbsp;<a href="/privacy/">Privacy Policy</a>,
              which also governs your visit to the TrustOnCloud Site, to
              understand our practices.
            </p>

            <h3>ELECTRONIC COMMUNICATIONS</h3>

            <p>
              When you visit the TrustOnCloud Site or send e-mails to us, you
              are communicating with us electronically. You consent to receive
              communications from us electronically. We will communicate with
              you by e-mail or by posting notices on the TrustOnCloud Site. You
              agree that all agreements, notices, disclosures and other
              communications that we provide to you electronically satisfy any
              legal requirement that such communications be in writing.
            </p>

            <h3>COPYRIGHT</h3>

            <p>
              All content included on the TrustOnCloud Site, such as text,
              graphics, logos, button icons, images, audio clips, digital
              downloads, data compilations, and software, is the property of
              TrustOnCloud or its content suppliers and protected by Singapore
              and international copyright laws. The compilation of all content
              on the TrustOnCloud Site is the exclusive property of TrustOnCloud
              and protected by Singapore and international copyright laws. All
              software used on the TrustOnCloud Site is the property of
              TrustOnCloud or its software suppliers and protected by Singapore
              and international copyright laws.
            </p>

            <p>
              Additionally, when you purchase any of our products, you will be
              bound by the Service Terms and Licence Agreement applicable to
              each product as may be applicable.
            </p>

            <h3>TRADEMARKS</h3>

            <p>
              TrustOnCloud graphics, logos, page headers, button icons, scripts,
              and service names are trademarks, registered trademarks or trade
              dress of TrustOnCloud in Singapore and/or other countries.
              TrustOnCloud’s trademarks and trade dress may not be used in
              connection with any product or service that is not TrustOnCloud’s,
              in any manner that is likely to cause confusion among customers,
              or in any manner that disparages or discredits TrustOnCloud. All
              other trademarks not owned by TrustOnCloud that appear on this
              Site are the property of their respective owners, who may or may
              not be affiliated with, connected to, or sponsored by
              TrustOnCloud.
            </p>

            <h3>LICENSE AND SITE ACCESS</h3>

            <p>
              TrustOnCloud grants you a limited license to access and make
              personal use of the TrustOnCloud Site and not to download (other
              than page caching) or modify it, or any portion of it, except with
              express written consent of TrustOnCloud. This license does not
              include any resale or commercial use of the TrustOnCloud Site or
              its contents; any derivative use of the TrustOnCloud Site or its
              contents; any downloading or copying of account information; or
              any use of data mining, robots, or similar data gathering and
              extraction tools. Unless otherwise specified by TrustOnCloud in a
              separate license, your right to use any software, data,
              documentation or other materials that you access or download
              through the TrustOnCloud Site is subject to these Site Terms or,
              if you have an TrustOnCloud account, the Agreement.
            </p>

            <p>
              The TrustOnCloud Site or any portion of the TrustOnCloud Site may
              not be reproduced, duplicated, copied, sold, resold, visited, or
              otherwise exploited for any commercial purpose without express
              written consent of TrustOnCloud. You may not frame or utilize
              framing techniques to enclose any trademark, logo, or other
              proprietary information (including images, text, page layout, or
              form) of TrustOnCloud without express written consent. You may not
              use any meta tags or any other “hidden text” utilizing
              TrustOnCloud’s name or trademarks without the express written
              consent of TrustOnCloud. Any unauthorized use terminates the
              permission or license granted by TrustOnCloud. You are granted a
              limited, revocable, and nonexclusive right to create a hyperlink
              to the homepage of the TrustOnCloud Site, so long as the link does
              not portray TrustOnCloud, or its products or services in a false,
              misleading, derogatory, or otherwise offensive matter. You may not
              use any TrustOnCloud logo or other proprietary graphic or
              trademark as part of the link without express written permission.
            </p>

            <h3>YOUR ACCOUNT</h3>

            <p>
              If you use the TrustOnCloud Site, you are responsible for
              maintaining the confidentiality of your TrustOnCloud Account and
              associated email and for restricting access to your computer, and
              you agree to accept responsibility for all activities that occur
              under your account or associated email. TrustOnCloud reserves the
              right to refuse service, terminate accounts, remove or edit
              content in its sole discretion.
            </p>

            <h3>REVIEWS, COMMENTS, COMMUNICATIONS, AND OTHER CONTENT</h3>

            <p>
              Visitors may post reviews, comments and other content; and submit
              suggestions, ideas, comments, questions, or other information, so
              long as the content is not illegal, obscene, threatening,
              defamatory, invasive of privacy, infringing of intellectual
              property rights, or otherwise injurious to third parties or
              objectionable and does not consist of or contain software viruses,
              political campaigning, commercial solicitation, chain letters,
              mass mailings, or any form of “spam.” You may not use a false
              e-mail address, impersonate any person or entity, or otherwise
              mislead as to the origin of a card or other content. TrustOnCloud
              reserves the right (but not the obligation) to remove or edit such
              content, but does not regularly review posted content. If you do
              post content or submit material, and unless we indicate otherwise,
              you grant TrustOnCloud a nonexclusive, royalty-free, perpetual,
              irrevocable, and fully sublicensable right to use, reproduce,
              modify, adapt, publish, translate, create derivative works from,
              distribute, and display such content throughout the world in any
              media. You grant TrustOnCloud and sublicensees the right to use
              the name that you submit in connection with such content, if they
              choose.
            </p>

            <h3>DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</h3>

            <p>
              THE TRUSTONCLOUD SITE AND ALL INFORMATION, CONTENT, MATERIALS,
              PRODUCTS (INCLUDING ANY SOFTWARE) AND SERVICES INCLUDED ON OR
              OTHERWISE MADE AVAILABLE TO YOU THROUGH THIS SITE ARE PROVIDED BY
              TRUSTONCLOUD ON AN “AS IS” AND “AS AVAILABLE” BASIS, UNLESS
              OTHERWISE SPECIFIED IN THE AGREEMENT. TRUSTONCLOUD MAKES NO
              REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS
              TO THE OPERATION OF THIS SITE OR THE INFORMATION, CONTENT,
              MATERIALS, PRODUCTS (INCLUDING ANY SOFTWARE) OR SERVICES INCLUDED
              ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE TRUSTONCLOUD
              SITE, UNLESS OTHERWISE SPECIFIED IN WRITING. YOU EXPRESSLY AGREE
              THAT YOUR USE OF THIS SITE IS AT YOUR SOLE RISK. TO THE FULL
              EXTENT PERMISSIBLE BY APPLICABLE LAW, TRUSTONCLOUD DISCLAIMS ALL
              WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
              IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
              PURPOSE. TRUSTONCLOUD DOES NOT WARRANT THAT THIS SITE;
              INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING ANY SOFTWARE)
              OR SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH
              THE TRUSTONCLOUD SITE; ITS SERVERS; OR E-MAIL SENT FROM
              TRUSTONCLOUD ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
              TRUSTONCLOUD WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND
              ARISING FROM THE USE OF THE TRUSTONCLOUD SITE OR FROM ANY
              INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR
              SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH
              THE TRUSTONCLOUD SITE, INCLUDING, BUT NOT LIMITED TO DIRECT,
              INDIRECT, INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES, UNLESS
              OTHERWISE SPECIFIED IN THE AGREEMENT. CERTAIN STATE LAWS DO NOT
              ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR
              LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR
              ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT
              APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.
            </p>

            <h3>THIRD PARTY LINKS</h3>

            <p>
              The Website and some of the articles on the Website may contain
              links to other resources and businesses on the Internet. We
              provide these citations and aids to help you identify and locate
              other Internet resources that may be of interest. We are not
              responsible for any loss, injury, claim, liability, or damage
              (“Loss”) related to your use of any website linked to the Website
              (“Linked Website”) including if this Loss (i) arises from errors
              or omissions in the content of any Linked Website, or (ii) is as a
              result of any Linked Website being down or (iii) arises from any
              other use of any Linked Website. Your use of any Linked Website is
              at your own risk. In addition, the links do not mean that we
              sponsor, are affiliated to or associated with, or legally
              authorised to use, any trade name, registered trademark, logo,
              legal or official seal, or copyrighted symbol in the links.
            </p>

            <h3>SECURITY</h3>

            <p>
              We care about the security of our users. While we work to protect
              the security of your account and related information, TrustOnCloud
              cannot guarantee that unauthorized third parties will not be able
              to defeat our security measures. Please notify us immediately of
              any compromise or unauthorized use of your account by
              emailing&nbsp;
              <a href="mailto:chatbot+security@trustoncloud.com">
                chatbot+security@trustoncloud.com
              </a>
              .
            </p>

            <h3>APPLICABLE LAW</h3>

            <p>
              By visiting the TrustOnCloud Site, you agree that the laws of the
              Republic of Singapore, without regard to principles of conflict of
              laws, will govern these Site Terms and any dispute of any sort
              that might arise between you and TrustOnCloud.
            </p>

            <h3>DISPUTES</h3>

            <p>
              Any dispute relating in any way to your visit to the TrustOnCloud
              Site or to services provided by TrustOnCloud or through the
              TrustOnCloud Site in which the aggregate total claim for relief
              sought on behalf of one or more parties exceeds $7,500 shall be
              adjudicated in any state court in the Republic of Singapore, and
              you consent to exclusive jurisdiction and venue in such courts.
            </p>

            <p>
              If you have a complaint, question, or dispute (collectively,
              “Dispute”) regarding the Website, you are encouraged to bring it
              to our attention. You may contact us at&nbsp;
              <a href="mailto:chatbot+dispute@trustoncloud.com">
                chatbot+dispute@trustoncloud.com
              </a>
              , and we will try to address your concerns.
            </p>

            <p>
              You agree that, regardless of any law or statute, any claim or
              cause of action you may have against us must be brought within one
              year after the claim or cause of action arises or is known or
              should reasonably have been known to you, or the claim or cause of
              action will be forever barred.
            </p>

            <p>
              You agree to indemnify, defend, and hold us harmless from any and
              all claims, liabilities, expenses, and damages, including
              reasonable legal fees and costs, made by any third party related
              to: (a) your use or attempted use of the Services in violation of
              these Terms or your violation of any law or rights of any third
              party including without limitation any claim of infringement or
              misappropriation of intellectual property or other proprietary
              rights.
            </p>

            <h3>SITE POLICIES, MODIFICATION, AND SEVERABILITY</h3>

            <p>
              Please review our other policies on the TrustOnCloud Site. These
              policies also govern your visit to the TrustOnCloud Site. We
              reserve the right to make changes to the TrustOnCloud Site,
              policies, and these Site Terms at any time at our sole discretion.
              For any material changes to the Terms, we will take reasonable
              steps to notify you of such changes, via a banner on the website,
              email notification, another method, or combination of methods. In
              all cases, your continued use of the Services after publication of
              such changes, with or without notification, constitutes binding
              acceptance of the revised Terms. If any of these conditions shall
              be deemed invalid, void, or for any reason unenforceable, that
              condition shall be deemed severable and shall not affect the
              validity and enforceability of any remaining condition.
            </p>

            <h3>OUR ADDRESS</h3>

            <p>196 Pandan Loop</p>

            <p>#07-17 Pantech Business Hub</p>

            <p>Singapore 128384</p>

            <p>
              <a href="https://www.trustoncloud.com">
                TrustOnCloud
              </a>
            </p>

            <h3>CHANGE LOG</h3>

            <p>April 3, 2020: Initial release</p>
          </InnerWidthCont>
      </Wrapper>
    </>
  );
};
