import axios from "axios";
import Papa from "papaparse";

import { apiUrl } from "../../config/constants";


const content = {
  threatModel: "json",
  events: "csv"
};

const getEventLog = (event_name) => {
  // Replace this with your actual logic to fetch event log based on event_name
  return `Event log for ${event_name}`;
};

const getPreSignedURL = async (filepath) => {
  const response = await axios.get(`${apiUrl}/presign-url`, {params: { filepath }});
  return response.data.url;
};

// Function to update the schema with inferred data types
// const updateSchema = (key, parsedValue, schema) => {
//   if (!schema[key]) {
//     schema[key] = typeof parsedValue;
//   } else {
//     const currentType = schema[key];
//     const newType = typeof parsedValue;

//     // Check if the new type is already included in the current type
//     if (!currentType.includes(newType)) {
//       schema[key] = `${currentType},${newType}`;
//     }
//   }

//   // If the parsed value is an object, specify data types for nested keys
//   if (typeof parsedValue === "object" && parsedValue !== null) {
//     getJSONSchema(parsedValue, key, schema);
//   }
// };

// Function to specify data types for nested keys in JSON data
// const getJSONSchema = (object, parentKey, schema) => {
//   for (const key in object) {
//     if (object.hasOwnProperty(key)) {
//       const value = object[key];
//       const fullKey = `${parentKey}.${key}`;

//       // Update schema to include the parsed value's type(s)
//       updateSchema(fullKey, value, schema);
//     }
//   }
// };

const getFilename = (provider, service, type) => {
  if (type === content.threatModel)
    return `public/${provider}-${service}.json`;
  else if (type === content.events)
    return `public/${provider}-${service}.csv`;
};

const getServiceName = (provider) => {
  return provider === "aws" ? "s3" :
    provider === "gcp" ? "bigquery" :
    provider === "azure" ? "storage" : null;
}

export const getAPIMapping = async (provider) => {
  const filename = getFilename(
    provider,
    getServiceName(provider),
    content.threatModel
  );

  try {
    const preSignedURL = await getPreSignedURL(filename);
    const response = await axios.get(preSignedURL);

    const result = [];
    Object.values(response.data.actions).forEach(action => {
      const { api, iam_permission, event_name } = action;
      let permissionNames = iam_permission.split(",");
      permissionNames = permissionNames.map(name => name.trim());
      let existingApi = result.find(item => item.api === api);

      if (!existingApi) {
        existingApi = { api, permissions: [] };
        result.push(existingApi);
      }

      permissionNames.forEach(permissionName => {
        let existingPermission = existingApi.permissions
          .find(permission => permission.iam_permission === permissionName);

        if (!existingPermission) {
          existingPermission = { iam_permission: permissionName, events: [] };
          existingApi.permissions.push(existingPermission);
        }

        const existingEvent = existingPermission.events
          .find(event => event.event_name === event_name);

        if (!existingEvent) {
          const duplicateEventPermission = existingApi.permissions.find(
            perm =>
              perm.iam_permission !== permissionName &&
              perm.events.some(event => event.event_name === event_name)
          );

          if (duplicateEventPermission) {
            const duplicateEventIndex = duplicateEventPermission.events
              .findIndex(event => event.event_name === event_name);
            duplicateEventPermission.events.splice(duplicateEventIndex, 1);
          }

          existingPermission.events.push({
            event_name,
            event_log: getEventLog(event_name)
          });
        }
      });
    });

    return result.filter(item => item.api !== "-");
  } catch (error) {
    console.error("Error:", error.message);
    return [];
  }
};

export const getEventsSchema = async (provider) => {
  const filename = getFilename(
    provider,
    getServiceName(provider),
    content.events
  );
  const preSignedURL = await getPreSignedURL(filename);
  const response = await axios.get(preSignedURL);

  const result = Papa.parse(response.data, { header: true });
  result.data.forEach(obj => {
    Object.entries(obj).forEach(([key, value]) => {
      if (typeof value === "string") {
        try {
          obj[key] = JSON.parse(value);
        } catch (error) {
          // If parsing fails, leave the value as is
        }
      }
    });
  });
  return result.data.filter(item => item.eventname);
};
