import React from "react";
import { spacing } from "@mui/system";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";
import { Button as MuiButton, Typography } from "@mui/material";

import NavbarIcon from "../../components/navbar/navbarIcon";

const Button = styled(MuiButton)(spacing);
const Wrapper = styled.div`
  padding-top: ${(props) => props.theme.spacing(6)};
`;

const InnerWidthCont = styled.div`
  width: 90%;
  margin:0 auto;
  text-align: justify;
`;

export const PrivacyPolicy = () => {
  return (
    <>
      <NavbarIcon />
      <Wrapper>
        <Helmet title="Privacy Policy" />
        <Typography align="center">
          <Button
            component={Link}
            to="/"
            variant="contained"
            color="secondary"
            mt={2}
          >
            Return to website
          </Button>
        </Typography>

        <InnerWidthCont>
          <h1>
            Privacy Policy
          </h1>
          <p>
            <em>Last Updated: April 3, 2020</em>
          </p>

          <p>
            Your privacy is critically important to us. At TrustOnCloud, we have
            a few fundamental principles:
          </p>

          <ul>
            <li>
              We are thoughtful about the personal information we ask you to
              provide and the personal information that we collect about you
              through the operation of our services.
            </li>
            <li>
              We store personal information for only as long as we have a reason
              to keep it. We aim for full transparency on how we gather, use,
              and share your personal information.
            </li>
            <li>
              We follow different principles in accordance with worldwide
              practices for customer privacy and data protection.
            </li>
          </ul>

          <p>
            Below is our Privacy Policy, which incorporates and clarifies these
            principles.
          </p>

          <p>
            This Privacy Policy describes how we collect and use your personal
            information in relation to TrustOnCloud websites, applications,
            products, services, events, and experiences that reference this
            Privacy Policy (together, “TrustOnCloud Offerings”).
          </p>

          <h3>Personal Information We Collect</h3>

          <p>
            We collect your personal information in the course of providing
            TrustOnCloud Offerings to you.
          </p>

          <p>Here are the types of information we gather:</p>

          <ul>
            <li>
              Information You Give Us: We collect any information you provide in
              relation to TrustOnCloud Offerings.
            </li>
            <li>
              Automatic Information: We automatically collect certain types of
              information when you interact with TrustOnCloud Offerings.
            </li>
            <li>
              Information from Other Sources: We might collect information about
              you from other sources, including service providers, partners, and
              publicly available sources.
            </li>
          </ul>

          <h3>How We Use Personal Information</h3>

          <p>
            We will not sell or give away your name, mail address, phone number,
            email address or any other information to anyone.
          </p>

          <p>
            We use state-of-the-art security measures to protect your
            information from unauthorized users.
          </p>

          <p>
            We use your personal information to operate, provide, and improve
            TrustOnCloud Offerings. Our purposes for using personal information
            include:
          </p>

          <ul>
            <li>
              Provide TrustOnCloud Offerings: We use your personal information
              to provide and deliver TrustOnCloud Offerings and process
              transactions related to TrustOnCloud Offerings, including
              registrations, subscriptions, purchases, and payments.
            </li>
            <li>
              Measure, Support, and Improve TrustOnCloud Offerings: We use your
              personal information to measure use of, analyze performance of,
              fix errors in, provide support for, improve, and develop
              TrustOnCloud Offerings. We do save billing information in case you
              want to order from us again, but we won’t use this information
              again without your permission.
            </li>
            <li>
              Recommendations and Personalization: We use your personal
              information to recommend TrustOnCloud Offerings that might be of
              interest to you, identify your preferences, and personalize your
              experience with TrustOnCloud Offerings. We also use it to make the
              site easier for you to use by not having to enter information more
              than once.
            </li>
            <li>
              Comply with Legal Obligations: In certain cases, we have a legal
              obligation to collect, use, or retain your personal information.
            </li>
            <li>
              Communicate with You: We use your personal information to
              communicate with you in relation to TrustOnCloud Offerings via
              different channels (e.g., by phone, email, chat) and to respond to
              your requests.
            </li>
            <li>
              Marketing: We use your personal information to market and promote
              TrustOnCloud Offerings. We might display interest-based ads for
              TrustOnCloud Offerings.
            </li>
            <li>
              Fraud and Abuse Prevention and Credit Risks: We use your personal
              information to prevent and detect fraud and abuse in order to
              protect the security of our customers, TrustOnCloud, and others.
              We may also use scoring methods to assess and manage credit risks.
              Purposes for
            </li>
            <li>
              Which We Seek Your Consent: We may also ask for your consent to
              use your personal information for a specific purpose that we
              communicate to you.
            </li>
          </ul>

          <h3>Cookies</h3>

          <p>
            We use cookies, pixels, and other similar technologies
            (collectively, “cookies”) to recognize your browser or device, learn
            more about your interests, provide you with essential features and
            services, and for additional purposes, including:
          </p>

          <ul>
            <li>
              Recognizing you when you sign in use our offerings. This allows us
              to provide you with recommendations, display personalized content,
              and provide other customized features and services.
            </li>
            <li>
              Keeping track of your specific preferences. This allows us to
              honour your likes and dislikes, such as your language and
              configuration preferences.
            </li>
            <li>
              Conducting research and diagnostics to improve our offerings.
            </li>
            <li>Preventing fraudulent activity.</li>
            <li>Improving security.</li>
            <li>
              Delivering content, including ads, relevant to your interests on
              TrustOnCloud sites and third-party sites (see the Interest-Based
              Ads notice for how we use cookies in serving interest-based ads).
            </li>
            <li>Reporting.</li>
          </ul>

          <p>
            This allows us to measure and analyze the performance of our
            offerings. TrustOnCloud cookies allow you to take advantage of some
            of essential TrustOnCloud features. For instance, if you block or
            otherwise reject our cookies, you might not be able to use certain
            offerings that require you to sign in, or you might have to manually
            adjust some preferences or language settings every time you visit
            TrustOnCloud sites.
          </p>

          <p>
            Approved third parties may also set cookies when you interact with
            our offerings. Third parties include search engines, providers of
            measurement and analytics services, social media networks, and
            advertising companies. Third parties use cookies in the process of
            delivering content, including ads relevant to your interests, to
            measure the effectiveness of their ads, and to perform services on
            behalf of TrustOnCloud.
          </p>

          <p>
            You can manage browser cookies through your browser settings. The
            ‘Help’ feature on most browsers will tell you how to prevent your
            browser from accepting new cookies, how to have the browser notify
            you when you receive a new cookie, how to disable cookies, and when
            cookies will expire. If you disable all cookies on your browser,
            neither we nor third parties will transfer cookies to your browser.
            If you do this, however, you may have to manually adjust some
            preferences every time you visit a site and some features and
            services may not work.
          </p>

          <h3>How We Share Personal Information</h3>

          <p>
            Information about our customers is an important part of our business
            and we are not in the business of selling our customers’ personal
            information to others. We share personal information only as
            described below and with TrustOnCloud that are either subject to
            this Privacy Policy or follow practices at least as protective as
            those described in this Privacy Policy.
          </p>

          <ul>
            <li>
              Transactions Involving Third Parties: We make available to you
              services, software, and content provided by third parties for use
              on or through TrustOnCloud Offerings. You can tell when a third
              party is involved in your transactions, and we share information
              related to those transactions with that third party.
            </li>
            <li>
              Third-Party Service Providers: We employ other companies and
              individuals to perform functions on our behalf. Examples include:
              delivering TrustOnCloud hardware, sending communications,
              processing payments, assessing credit and compliance risks,
              analyzing data, providing marketing and sales assistance
              (including advertising and event management), conducting customer
              relationship management, and providing training. These third-party
              service providers have access to personal information needed to
              perform their functions, but may not use it for other purposes.
              Further, they must process that information in accordance with
              this Privacy Policy and as permitted by applicable data protection
              law.
            </li>
            <li>
              Business Transfers: As we continue to develop our business, we
              might sell or buy businesses or services. In such transactions,
              personal information generally is one of the transferred business
              assets but remains subject to the promises made in any
              pre-existing Privacy Policy (unless, of course, the individual
              consents otherwise). Also, in the unlikely event that TrustOnCloud
              or substantially all of its assets are acquired, your information
              will of course be one of the transferred assets.
            </li>
            <li>
              Protection of Us and Others: We release Account Information and
              other personal information when we believe release is appropriate
              to comply with the law, enforce or apply our terms and other
              agreements, or protect the rights, property, or security of
              TrustOnCloud, our customers, or others. This includes exchanging
              information with other companies and organizations for fraud
              prevention and detection and credit risk reduction.
            </li>
            <li>
              At Your Option: Other than as set out above, you will receive
              notice when personal information about you might be shared with
              third parties, and you will have an opportunity to choose not to
              share the information.
            </li>
          </ul>

          <h3>Location of Personal Information</h3>

          <p>
            Inside the TrustOnCloud, its subsidiaries, affiliates or agents,
            data is stored in password-controlled servers with limited access.
            Your information may be stored and processed in Singapore or any
            other country. Depending on the scope of your interactions with
            TrustOnCloud Offerings, your personal information may be stored in
            or accessed from multiple countries, including the United States and
            Singapore.
          </p>

          <p>
            Whenever we transfer personal information to other jurisdictions, we
            will ensure that the information is transferred in accordance with
            this Privacy Policy and as permitted by applicable data protection
            laws.
          </p>

          <h3>How We Secure Information</h3>

          <p>
            At TrustOnCloud, security is our highest priority. We design our
            systems with your security and privacy in mind. We protect the
            security of your information during transmission to or from
            TrustOnCloud websites, applications, products, or services by using
            encryption protocols and software. We maintain physical, electronic,
            and procedural safeguards in connection with the collection,
            storage, and disclosure of personal information. We also take strong
            precautions to protect your data from loss, misuse, unauthorized
            access or disclosure, alteration, or destruction.
          </p>
          
          <p>
            Our security procedures mean that we may request proof of identity
            before we disclose personal information to you.
          </p>

          <h3>Access and Choice</h3>

          <p>
            You can view, update, and delete certain information about your
            account and your interactions with TrustOnCloud Offerings. You have
            choices about the collection and use of your personal information.
            Many TrustOnCloud Offerings include settings that provide you with
            options as to how your information is being used. You can choose not
            to provide certain information, but then you might not be able to
            take advantage of certain TrustOnCloud Offerings.
          </p>

          <ul>
            <li>
              Account Information: If you want to add, update, or delete
              information related to your account, please request it by sending
              an email to chatbot@trustoncloud.com. When you update or delete
              any information, we usually keep a copy of the prior version for
              our records.
            </li>
            <li>
              Communications: If you do not want to receive promotional messages
              from us, please unsubscribe or adjust your communication
              preferences by sending your request to chatbot@trustoncloud.com.
              If you do not want to receive in-app notifications from us, please
              adjust your notification settings in the app or your device.
            </li>
            <li>
              Browser and Devices: The Help feature on most browsers and devices
              will tell you how to prevent your browser or device from accepting
              new cookies, how to have the browser notify you when you receive a
              new cookie, or how to disable cookies altogether.
            </li>
          </ul>

          <h3>Children’s Personal Information</h3>

          <p>
            We don’t provide any TrustOnCloud Offerings for purchase by
            children. If you’re under 18, you may use TrustOnCloud Offerings
            only with the involvement of a parent or guardian.
          </p>

          <h3>Retention of Personal Information</h3>

          <p>
            We keep your personal information to enable your continued use of
            TrustOnCloud Offerings, for as long as it is required in order to
            fulfill the relevant purposes described in this Privacy Policy, as
            may be required by law (including for tax and accounting purposes),
            or as otherwise communicated to you. How long we retain specific
            personal information varies depending on the purpose for its use,
            and we will delete your personal information in accordance with
            applicable law.
          </p>

          <h3>Contacts, Notices, and Revisions</h3>

          <p>
            If you have any concern about privacy at TrustOnCloud or want to
            contact one of our data controllers, please contact us at
            legal+privacy@trustoncloud.com with a thorough description, and we
            will try to resolve it. For any prospective or current customers,
            our mailing address is: 196 Pandan Loop, #07-17 Pantech Business
            Hub, Singapore 128384, ATTN: TrustOnCloud Legal. Be certain the
            words “Privacy Policy” are in the Subject line.
          </p>

          <p>
            If you interact with TrustOnCloud Offerings on behalf of or through
            your organization, then your personal information may also be
            subject to your organization’s privacy practices, and you should
            direct privacy inquiries to your organization.
          </p>

          <p>
            Our business changes constantly, and our Privacy Policy may also
            change. You should check our website frequently to see recent
            changes. You can see the date on which the latest version of this
            Privacy Policy was posted. Unless stated otherwise, our current
            Privacy Policy applies to all personal information we have about you
            and your account. We stand behind the promises we make, however, and
            will never materially change our policies and practices to make them
            less protective of personal information collected in the past
            without informing affected customers and giving them a choice.
          </p>

          <h3>Examples of Information Collected</h3>

          <h4>Information You Give Us</h4>

          <p>
            You provide information to us when you: search for, subscribe to, or
            purchase TrustOnCloud Offerings; create or administer your
            TrustOnCloud account (and you might have more than one account if
            you have used more than one email address when using TrustOnCloud
            Offerings); configure your settings for, provide data access
            permissions for, or otherwise interact with TrustOnCloud Offerings;
            register for or attend an TrustOnCloud event; communicate with us by
            phone, email, or otherwise; complete a questionnaire, a support
            ticket, or other information request forms; employ notification
            services.
          </p>

          <p>
            Depending on your use of TrustOnCloud Offerings, you might supply us
            with such information as: your name, email address, physical
            address, phone number, and other similar contact information;
            payment information, including credit card and bank account
            information; information about your location; information about your
            organization and your contacts, such as colleagues or people within
            your organization; usernames, aliases, roles, and other
            authentication and security credential information; content of
            feedback, testimonials, inquiries, support tickets, and any phone
            conversations, chat sessions and emails with or to us; your image
            (still, video, and in some cases 3-D), voice, and other identifiers
            that are personal to you when you attend an TrustOnCloud event;
            information regarding identity, including government-issued
            identification information; corporate and financial information; and
            VAT numbers and other tax identifiers.
          </p>

          <h4>Automatic Information</h4>

          <p>
            We collect information automatically when you: visit, interact with,
            or use TrustOnCloud Offerings (including when you use your computer
            or other device to interact with TrustOnCloud Offerings); download
            content from us; open emails or click on links in emails from us;
            and interact or communicate with us (such as when you attend an
            TrustOnCloud event or when you request customer support).
          </p>

          <p>
            Examples of the information we automatically collect include:
            network and connection information, such as the Internet protocol
            (IP) address used to connect your computer or other device to the
            Internet and information about your Internet service provider;
            computer and device information, such as device, application, or
            browser type and version, browser plug-in type and version,
            operating system, or time zone setting; the location of your device
            or computer; authentication and security credential information;
            content interaction information, such as content downloads, streams,
            and playback details, including duration and number of simultaneous
            streams and downloads; TrustOnCloud Offerings metrics, such as
            offering usage, occurrences of technical errors, diagnostic reports,
            your settings preferences, backup information, API calls, and other
            logs; the full Uniform Resource Locators (URL) clickstream to,
            through, and from our website (including date and time) and
            TrustOnCloud Offerings, content you viewed or searched for, page
            response times, download errors, and page interaction information
            (such as scrolling, clicks, and mouse-overs); email addresses and
            phone numbers used to contact us; and identifiers and information
            contained in cookies.
          </p>

          <h4>Information from Other Sources</h4>

          <p>
            Examples of information we receive from other sources include:
            marketing, sales generation, and recruitment information, including
            your name, email address, physical address, phone number, and other
            similar contact information; subscription, purchase, support, or
            other information about your interactions with products and services
            offered by us, our affiliates; search results and links, including
            paid listings (such as Sponsored Links); and credit history
            information from credit bureaus.
          </p>

          <h4>Information You Can Access</h4>

          <p>
            Examples of information you can access through TrustOnCloud
            Offerings include: your name, email address, physical address, phone
            number, and other similar contact information; usernames, aliases,
            roles, and other authentication and security credential information;
            your subscription, purchase, usage, billing, and payment history;
            payment settings, such as payment instrument information and billing
            preferences; tax information; email communication and notification
            settings
          </p>

          <h3>Change log</h3>

          <p>April 3, 2020: Initial release</p>
        </InnerWidthCont>
      </Wrapper>
    </>
  );
};
