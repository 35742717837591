import React from "react";
import { spacing } from "@mui/system";
import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";
import {
  Box as MuiBox,
  Drawer as MuiDrawer,
  ListItemButton,
} from "@mui/material";

import SidebarNav from "./sidebarNav";
import SidebarFooter from "./sidebarFooter";
import SiteLogo from "../../assets/logo.png";

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Brand = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(3)};
  padding-right: ${(props) => props.theme.spacing(3)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const Logo = () => (
  <img src={SiteLogo} alt="site logo" width={"32px"} height={"17px"} />
);

const Box = styled(MuiBox)(spacing);
const BrandIcon = styled(Logo)`
  margin-right: ${(props) => props.theme.spacing(2)};
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
`;

const Sidebar = ({ items, showFooter = false, ...rest }) => (
  <Drawer variant="permanent" {...rest}>
    <Brand component={NavLink} to="/">
      <BrandIcon />
      <Box ml={2}>Cloud Security Dataset</Box>
    </Brand>
    <SidebarNav items={items} />
    {showFooter && <SidebarFooter />}
  </Drawer>
);

export default Sidebar;
