import { styled } from "@mui/system";
import { Helmet } from "react-helmet-async";
import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

import { allowedUrls } from "../config/constants";
import { allowedApps } from "../config/constants";
import { MainComponent } from "../components/main";
import { APITable } from "../components/table/api";
import { CenteredDiv } from "../services/styles/styles";
import { EventsTable } from "../components/table/events";
import { getAPIMapping, getEventsSchema } from "../services/data";

const StyledCenteredDiv = styled(CenteredDiv)(() => ({
  "@media screen and (min-width: 960px)": {
    width: "calc(95vw - 20vw)"
  }
}));

export const Main = () => {
  const { hash: tab } = useLocation();
  const { appName, provider } = useParams();
  const [apiMapping, setApiMapping] = useState([]);
  const [eventsSchema, setEventsSchema] = useState([]);

  useEffect(() => {
    setApiMapping([]);
    setEventsSchema([]);
    if (provider) {
      if (appName === allowedUrls.mapping)
        getAPIMapping(provider).then(data => setApiMapping(data));
      if (appName === allowedUrls.events)
        getEventsSchema(provider).then(data => setEventsSchema(data));
    }
  }, [appName, provider]);

  const hasApp = (name) => allowedApps.map(item => item.url).includes(name);
  return (
    <>
      {
        hasApp(appName) ?
        <>
          <Helmet title={allowedApps[appName]} />
          {
            provider && (
              apiMapping.length > 0 || eventsSchema.length > 0
            ) ?
              appName === allowedUrls.mapping ?
                <APITable data={apiMapping} /> :
              appName === allowedUrls.events &&
                <EventsTable data={eventsSchema} /> :
            provider &&
              <StyledCenteredDiv>
                <CircularProgress variant="indeterminate" size={50} />
              </StyledCenteredDiv>
          }
          {!provider && <MainComponent activeTab={tab} />}
        </> :
        <>App not supported.</>
      }
    </>
  );
}
