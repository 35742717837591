import React from "react";
// import { styled } from "@mui/system";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  // Button,
  TableCell,
  TableRow,
} from "@mui/material";

// const StyledButton = styled(Button)`
//   border: none;
//   color: black;
//   font-weight: 400;
//   font-size: inherit;
//   padding-right: 1rem;
// `;

export const Row = ({ row }) => {
  // const [open, setOpen] = useState(false);

  return (
    <TableRow>
      <TableCell sx={{ whiteSpace: "normal", wordWrap: "break-word", maxWidth: "300px" }}>{row.api}</TableCell>
      <TableCell>
        {row.permissions.map(permission => (
          <div key={permission.iam_permission}>
            {permission.iam_permission}
          </div>
        ))}
      </TableCell>
      {/* <TableCell>
        {row.permissions.map(permission => (
          <div key={permission.iam_permission}>
            {permission.events.map(event => (
              <div key={event.event_name}>
                <StyledButton
                  onClick={() => setOpen(!open)}
                  size="small"
                  variant="text"
                >
                  { open ?
                    <KeyboardArrowDownIcon />:
                    <KeyboardArrowRightIcon />
                  }
                  <span style={{ margin: 0 }}>{event.event_name}</span>
                </StyledButton>
                {open && (
                  <p style={{
                    fontSize: "0.85em",
                    margin: "0.1rem 0",
                    paddingLeft: "1.7rem"
                  }}>{event.event_log}</p>
                )}
              </div>
            ))}
          </div>
        ))}
      </TableCell> */}
    </TableRow>
  );
};
