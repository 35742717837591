import awsIcon from "../../assets/aws.svg";
import azureIcon from "../../assets/azure.svg";
import googleIcon from "../../assets/google.svg";
import { allowedUrls } from "../../config/constants";
import { allowedApps } from "../../config/constants";
import {
  TaskAlt as TaskAltIcon,
  Launch as LaunchIcon,
} from "@mui/icons-material";


const predefinedSections = [
  {
    href: "aws",
    icon: () => <img src={awsIcon} alt="AWS ICON" />,
    title: "AWS",
    children: [{
      title: allowedApps.filter(
        app => app.url === allowedUrls.mapping
      ).pop().title,
      href: `aws/${allowedUrls.mapping}`,
      icon: () => <TaskAltIcon />
    }, {
      title: allowedApps.filter(
        app => app.url === allowedUrls.events
      ).pop().title,
      href: `aws/${allowedUrls.events}`,
      icon: () => <TaskAltIcon />
    }, {
      title: "AWS IAM Changes",
      href: "https://awsiamchanges.com",
      icon: () => <LaunchIcon />
    }],
  },
  {
    href: "azure",
    icon: () => (
      <img src={azureIcon} alt="Azure ICON" height={"32px"} width={"32px"} />
    ),
    title: "Microsoft Azure",
    children: [{
      title: allowedApps.filter(
        app => app.url === allowedUrls.mapping
      ).pop().title,
      href: `azure/${allowedUrls.mapping}`,
      icon: () => <TaskAltIcon />
    }, {
      title: "Azure IAM Changes",
      href: "https://azureiamchanges.com",
      icon: () => <LaunchIcon />
    }],
  },
  {
    href: "gcp",
    icon: () => (
      <img src={googleIcon} alt="GCP ICON" height={"32px"} width={"32px"} />
    ),
    title: "Google Cloud",
    children: [{
      title: allowedApps.filter(
        app => app.url === allowedUrls.mapping
      ).pop().title,
      href: `gcp/${allowedUrls.mapping}`,
      icon: () => <TaskAltIcon />
    }, {
      title: "GCP API Changes",
      href: "https://gcpapichanges.com",
      icon: () => <LaunchIcon />
    }],
  },
];

let loadedSections = predefinedSections.map(section => ({...section}));

export const updateSections = async () => {
  // placeholder function to update sections dynamically
  // after fetching data from an API, for example.

  loadedSections = predefinedSections.map(section => ({...section}));

  // TODO: do your modification / update here.

  return loadedSections;
};

export const sections = loadedSections;
export const navItems = [{
  title: "",
  pages: loadedSections,
}];
