import { create } from "jss";
import { useRoutes } from "react-router-dom";
import jssPreset from "@mui/styles/jssPreset";
import { ThemeProvider } from "styled-components/macro";
import StylesProvider from "@mui/styles/StylesProvider";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";

import "./i18n";
import { routes } from "./routes";
import createTheme from "./theme";
import useTheme from "./hooks/useTheme";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

function App() {
  const { theme } = useTheme();
  const routedContent = useRoutes(routes);
  const title = "Cloud Security Dataset";

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate={`%s | ${title}`}
        defaultTitle={title}
      />
      <StylesProvider jss={jss}>
        <MuiThemeProvider theme={createTheme(theme)}>
          <ThemeProvider theme={createTheme(theme)}>
            {routedContent}
          </ThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </HelmetProvider>
  );
}

export default App;
