import { styled } from "@mui/system";
import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  TableSortLabel,
  Box
} from "@mui/material";

import { Row } from "./row";

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  "&": {
    backgroundColor: theme.palette.primary.main,
    border: `2px solid ${theme.palette.table.headerBorder}`
  },
  "& th": {
    borderRight: `2px solid ${theme.palette.table.headerBorder}`,
    borderBottom: `2px solid ${theme.palette.table.headerBorder}`,
    fontSize: "16px",
    padding: "1rem"
  },
  "& th, & th span, & th svg": { color: "white !important" }
}));

const StyledTableBody = styled(TableBody)(({ theme }) => ({
  "& tr:nth-of-type(even)": {
    backgroundColor: "rgba(230, 245, 255)"
  },
  "& td": {
    border: `2px solid ${theme.palette.table.bodyBorder}`,
    fontSize: "14px"
  }
}));

export const APITable = ({ data }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [filterText, setFilterText] = useState("");

  useEffect(() => { setFilteredData(data) }, [data]);

  const handleSort = (property) => {
    const isAscending = sortBy === property;
    setSortBy(isAscending ? `-${property}` : property);
    const sortedData = filteredData.slice().sort((a, b) =>
      isAscending ?
        a[property].localeCompare(b[property]) :
        b[property].localeCompare(a[property])
    );
    setFilteredData(sortedData);
  };

  const handleFilter = (text) => {
    setFilterText(text);
    const filteredResults = data.filter(item =>
      item.api.toLowerCase().includes(text.toLowerCase())
    );
    setFilteredData(filteredResults);
  };

  return (
    <>
      <TextField
        label="Filter Table"
        variant="outlined"
        value={filterText}
        onChange={(e) => handleFilter(e.target.value)}
        style={{ marginBottom: "16px" }}
        sx={{ float: "right", minWidth: "33%" }}
      />
      <Box boxShadow={3}>
        <TableContainer component={Paper}>
          <Table>
            <StyledTableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={sortBy === "api"}
                    direction={sortBy === "api" ? "asc" : "desc"}
                    onClick={() => handleSort("api")}
                  >
                    API
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  Permissions
                </TableCell>
                {/* <TableCell>
                  Event Logs
                </TableCell> */}
              </TableRow>
            </StyledTableHead>
            <StyledTableBody>
              {filteredData.map(item => (
                <Row key={item.api} row={item} />
              ))}
            </StyledTableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};
