import React from "react";

import MainCard from "./card";
import { ProviderTabs } from "./providerTabs";
import { servicesConfig } from "../../config/services";

const prepareLabelAndContents = (servicesConfig) => {
  const labels = [];
  const contents = [];

  Object.keys(servicesConfig).forEach((service) => {
    const { title, ...config } = servicesConfig[service];
    labels.push(title);
    contents.push(
      <>
        {
          Object.keys(config).map((title) => (
            <MainCard
              key={title}
              Name={config[title].title}
              {...config[title]}
              exploreLink={config[title].href}
              rowview
            />
          ))
        }
      </>
    );
  });
  return { labels, contents };
};

export const MainComponent = ({ activeTab }) => (
  <ProviderTabs
    {...prepareLabelAndContents(servicesConfig)}
    activeTab={activeTab ? activeTab : "#AWS"}
    vertical
  />
);
