const FadeIn = (duration, iterations) => [
  [
    {
      opacity: 0.4,
    },
    {
      opacity: 0.6,
    },
    {
      opacity: 0.8,
    },
    {
      opacity: 1,
    },
  ],
  { duration, iterations },
];

const Scale = (
  initialValue,
  incrementValue,
  noOfIncrements,
  duration,
  iterations
) => {
  const frames = [];
  for (let i = 0; i < noOfIncrements; i++) {
    frames.push({
      transform: `scale(${initialValue + incrementValue * i})`,
    });
  }
  for (let i = noOfIncrements; i > 0; i--) {
    frames.push({
      transform: `scale(${initialValue + incrementValue * i})`,
    });
  }
  return [frames, { duration, iterations }];
};

const AnimateAndScroll = (section, pSection) =>
  setTimeout(() => {
    const el = document.getElementById(section);
    const parent = document.getElementById(pSection);
    if (parent && el) {
      el?.scrollIntoView({
        behavior: "smooth",
        block: section?.includes("TA") ? "center" : "start",
      });
      parent?.animate(...FadeIn(1500, 1));
      const ScaleAnim = section?.includes("TA")
        ? Scale(0.9, 0.1, 3, 1500, 2)
        : Scale(0.97, 0.03, 3, 1500, 2);
      el?.animate(...ScaleAnim);
    } else if (!el && !pSection?.includes("Actions")) {
      parent?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, 1500);

export const scrollToEl = (hash) => {
  if (hash) {
    let section;
    let pSection = hash.split("?")[0].replace("#", "");
    if (hash.includes("?")) {
      section = hash.split("?")[1];
    }
    AnimateAndScroll(section, pSection);
  }
};
