import React from "react";
import styled from "styled-components";
import { AppBar as MuiAppBar } from "@mui/material";

import { ReactComponent as Logo } from "../../assets/logo.svg";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const RowContainerIcon = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Brand = styled.div`
  font-size: ${(props) => props.theme.typography.h4.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  font-family: ${(props) => props.theme.typography.fontFamily};
  padding: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BrandIcon = styled(Logo)`
  margin-right: ${(props) => props.theme.spacing(2)};
  margin-top: -2px;
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  width: 48px;
  height: 48px;
  vertical-align: middle;
  display: inline;
`;

const NavbarIcon = () => {
  return (
    <>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          boxShadow:
            "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
        }}>
        <RowContainerIcon>
          <Brand>
            <BrandIcon />
            TrustOnCloud
          </Brand>
        </RowContainerIcon>
      </AppBar>
    </>
  );
};

export default NavbarIcon;
